// Libraries

// Supermove
import {useRef} from '@supermove/hooks';
import {FlatListType as RNFlatListType} from '@supermove/styles/types';

export type FlatListType<T> = {
  ref: React.MutableRefObject<RNFlatListType<T> | undefined>;
  handleScrollToOffset: ({offset, animated}: {offset: number; animated?: boolean}) => void;
  handleScrollToTop: ({animated}: {animated?: boolean}) => void;
  handleScrollToEnd: ({animated}: {animated?: boolean}) => void;
  handleScrollToIndex: ({index, animated}: {index: number; animated?: boolean}) => void;
};

const useScrollView = <T>(): FlatListType<T> => {
  const ref = useRef<RNFlatListType<T>>();
  const handleScrollToOffset = ({offset, animated}: {offset: number; animated?: boolean}) =>
    ref.current?.scrollToOffset({offset, animated});
  const handleScrollToTop = ({animated}: {animated?: boolean}) =>
    ref.current?.scrollToOffset({offset: 0, animated});
  const handleScrollToEnd = ({animated}: {animated?: boolean}) =>
    ref.current?.scrollToEnd({animated});
  const handleScrollToIndex = ({index, animated}: {index: number; animated?: boolean}) =>
    ref.current?.scrollToIndex({index, animated});

  return {
    ref,
    handleScrollToOffset,
    handleScrollToTop,
    handleScrollToEnd,
    handleScrollToIndex,
  };
};

export default useScrollView;
