// Supermove
import {useState} from '@supermove/hooks';

export type TabsType = {
  selectedIndex: number;
  setSelectedIndex: (index: number) => void;
  handleChangeIndex: (index: number) => void;
  handleNext: () => void;
  handlePrev: () => void;
  hasNext: boolean;
  hasPrev: boolean;
};

const useTabs = ({
  initialIndex,
  onChangeIndex,
  numberOfTabs = 0,
}: {
  initialIndex: number;
  onChangeIndex?: (index: number) => void;
  numberOfTabs?: number;
}) => {
  const [selectedIndex, setSelectedIndex] = useState(initialIndex);
  const hasNext = selectedIndex < numberOfTabs - 1;
  const hasPrev = selectedIndex > 0;

  const handleChangeIndex = (index: number) => {
    setSelectedIndex(index);
    if (onChangeIndex) {
      onChangeIndex(index);
    }
  };

  const handleNext = () => {
    if (hasNext) {
      setSelectedIndex(selectedIndex + 1);
    }
  };

  const handlePrev = () => {
    if (hasPrev) {
      setSelectedIndex(selectedIndex - 1);
    }
  };

  return {
    selectedIndex,
    setSelectedIndex,
    handleChangeIndex,
    handleNext,
    handlePrev,
    hasNext,
    hasPrev,
  };
};

export default useTabs;
