import {useEffect} from 'react';

type KeyEvent = 'keyup' | 'keydown';

interface KeyboardShortcutOptions {
  handleKeyPress: (event: KeyboardEvent) => void;
  eventType?: KeyEvent;
}

/**
 * A custom hook to handle keyboard shortcuts
 * @param options - Object containing the key handler and optional event type
 * @param options.handleKeyPress - Function to handle the keyboard event
 * @param options.eventType - Type of keyboard event to listen for ('keyup' or 'keydown')
 */
const useKeyboardShortcutEffect = ({
  handleKeyPress,
  eventType = 'keyup',
}: KeyboardShortcutOptions) => {
  useEffect(() => {
    // Add event listener
    window.addEventListener(eventType, handleKeyPress);

    // Clean up
    return () => {
      window.removeEventListener(eventType, handleKeyPress);
    };
  }, [handleKeyPress, eventType]);
};

export default useKeyboardShortcutEffect;
