import {useEffect} from '@supermove/hooks';

/**
 * Shows a system dialog warning when users attempt to reload while offline
 *
 * Known limitations:
 * - Doesn't work on iOS Safari (ignores beforeunload events)
 * - Potential alternatives: visibilitychange event, useBeforeRefreshEffect
 */

const useOfflineReloadWarningEffect = ({isConnected}: {isConnected: boolean}) => {
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (!isConnected) {
        event.preventDefault();
        event.returnValue = true; // Required for legacy feature support
        return true; // Required for legacy feature support
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [isConnected]);
};

export default useOfflineReloadWarningEffect;
